import covers from "../data/covers/*.jpg";

function cover(url) {
  if (!url) {
    return "";
  }

  const filename = url.split("/").pop().split(".")[0]

  return `
    <a href="${covers[filename]}" target="_blank">
      <img src="${covers[filename]}" class="card-img">
    </a>
  `;
}

function genres(names) {
  return names
    .map(
      (name) =>
        `<span class="badge badge-pill badge-primary mr-1">${name}</span>`
    )
    .join("");
}

function edition(name) {
  const content = {
    pl: "🇵🇱",
    jp: "🇯🇵",
  }[name];

  return content
    ? `<span class="badge float-right bg-white" role="img" aria-label="${name}">${
        content || name
      }</span>`
    : "";
}

function editions(names) {
  return names.map((name) => edition(name)).join("");
}

function synopsis(text) {
  if (!text) {
    return "";
  }

  return `
    <p class="card-text text-justify mb-0 synopsis" data-clamp="clamp">
      ${text}
    </p>
    <div class="text-right w-100">
      <button type="button" class="btn btn-sm btn-link" data-clamp="btn">
        <span data-clamp="more">show more</span>
        <span data-clamp="less">show less</span>
      </button>
    </div>
  `;
}

function malButton(malId) {
  if (malId) {
    return `<a href="https://myanimelist.net/manga/${malId}" target="_blank" class="btn btn-sm btn-link">Show on MyAnimeList.net</a>`;
  }

  return "";
}

function htmlToElement(html) {
  const template = document.createElement("template");
  html = html.trim();
  template.innerHTML = html;
  return template.content.firstChild;
}

function item(item) {
  const element = htmlToElement(`
<div class="card mb-3">
  <div class="${item.cover ? '' : 'nocover'}">
    <div class="float-left card-1">${cover(item.cover)}</div>
    <div class="card-body float-left pb-0 card-2">
      <div class="genres mb-2"><small>${genres(item.genres)}</small></div>
      <h3 class="card-title mb-0">
        ${editions(item.editions)}
        ${item.titles[0]}
      </h3>
      <p class="card-text text-muted mb-0">${item.titles
        .slice(1)
        .join(", ")}</p>
    </div>
    <div class="card-body float-left pt-1 card-3">
      ${synopsis(item.synopsis)}
      <div>
        ${malButton(item.mal_id)}
      </div>
    </div>
  </div>
</div>
  `);

  if (item.synopsis) {
    const moreElement = element.querySelector("[data-clamp='more']");
    const lessElement = element.querySelector("[data-clamp='less']");
    const clampAttr = element
      .querySelector("[data-clamp='clamp'], [data-clamp='']")
      .attributes.getNamedItem("data-clamp");

    moreElement.style.display = clampAttr.value ? "" : "none";
    lessElement.style.display = clampAttr.value ? "none" : "";

    element.querySelector("[data-clamp='btn']").addEventListener(
      "click",
      () => {
        clampAttr.value = clampAttr.value ? "" : "clamp";
        moreElement.style.display = clampAttr.value ? "" : "none";
        lessElement.style.display = clampAttr.value ? "none" : "";
      },
      false
    );
  }

  return element;
}

export default { item };
