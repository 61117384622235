import "./vendor/modernizr-3.11.2.min.js";
import "./plugins.js";

import templates from "./templates";
import data from "../data/data.json";

const resultCountElement = document.getElementById("resultCount");
const searchElement = document.getElementById("search");
const itemsElement = document.getElementById("items");
const clearElement = document.getElementById("clear");
const backToTopElement = document.querySelector("[data-backToTop]");

function onScroll() {
  const show =
    document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000;

  backToTopElement.style.display = show ? "" : "none";
}

function toUniformStr(str) {
  return str
    .toLowerCase()
    .replace(/[\s.!?\\-]/g, "")
    .replace("ą", "a")
    .replace("ć", "c")
    .replace("ę", "e")
    .replace("ł", "l")
    .replace("ń", "n")
    .replace("ó", "o")
    .replace("ś", "s")
    .replace("ź", "z")
    .replace("ż", "z");
}

function matchesQuery(query, item) {
  const uniformQuery = toUniformStr(query);
  for (const title of item.titles.map((x) => toUniformStr(x))) {
    if (title.search(uniformQuery) !== -1) {
      return true;
    }
  }

  return false;
}

function filterItems(query) {
  let matches = 0;
  data.forEach((item, idx) => {
    if (matchesQuery(query, item)) {
      itemsElement.children[idx].classList.remove("d-none");
      ++matches;
    } else {
      itemsElement.children[idx].classList.add("d-none");
    }
  });

  return matches;
}

function onSearchChange() {
  const matches = filterItems(searchElement.value);
  if (matches === data.length) {
    resultCountElement.innerHTML = `Showing all items (${matches}), sorted by most popular.`;
  } else {
    resultCountElement.innerHTML = `Showing ${matches} items containing \"${searchElement.value}\", sorted by most popular.`;
  }
}

function insertItems(items) {
  const fragment = document.createDocumentFragment();
  for (const item of items) {
    fragment.appendChild(templates.item(item));
  }
  itemsElement.appendChild(fragment);
}

function clear() {
  searchElement.value = "";
  onSearchChange();
}

// Entry point

window.addEventListener("scroll", onScroll, false);
searchElement.addEventListener("input", onSearchChange, false);
clearElement.addEventListener("click", clear, false);

onScroll();
insertItems(data);
onSearchChange();
